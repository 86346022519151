import React from 'react';

const Footer = ({
  links =   [],
  onClick,
  buttonText,
}) => {
  const currentYear = new Date().getFullYear();

  let linkElements = links.map((link) => (
    <a
      key={link._uid}
      href={link.link.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {link.text}
    </a>
  ));

  // Append privacy settings to the end of the footer links
  linkElements.push(
    <a
      key={'cookie-settings'}
      onClick={onClick}
    >
      {buttonText}
    </a>
  );

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="grid">
          <div>&copy; Blaguss {currentYear}</div>
          <div className="links">
            {linkElements}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
