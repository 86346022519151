import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import Storyblok from '../StoryblokService';

import Header from './Header';
import Footer from './Footer';

import SectionTitle from './SectionTitle';
import SectionTitleText from './SectionTitleText';
import SectionSubtitle from './SectionSubtitle';
import VideoSection from './VideoSection';

import Safety from './Safety';
import Services from './Services';
import SurveySection from './SurveySection';
import SocialMediaLinks from './SocialMediaLinks';
import CookieBar from './CookieBar';

import { loadCookieSettings } from '../helpers';

function LandingPage({ language }) {
  const cookieSettings = loadCookieSettings();
  const [data, setData] = useState(null);
  const [showCookieSettings, setShowCookieSettings] = useState(false);

  // `cookiesAccepted` aus sessionStorage initialisieren
  const [cookiesAccepted, setCookiesAccepted] = useState(() => {
    return sessionStorage.getItem('cookiesAccepted') === 'true';
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const diFull = queryParams.get('di');

  let di, options;

  if (diFull && diFull.length === 33) {
    di = diFull.slice(0, 32);
    options = parseInt(diFull.slice(-1), 10).toString();
  } else {
    di = diFull;
    options = null;
  }

  const showThankSection = queryParams.get('survey') === 'TRUE';
  const firstThreeDigits = di ? di.slice(0, 3) : '';

  useEffect(() => {
    const story = language === 'en' ? 'onboard-webapp-en' : 'onboard-webapp';
    Storyblok.get(`cdn/stories/${story}`, {
      version: 'draft'
    })
      .then(response => {
        setData(response.data.story.content);
      })
      .catch(error => {
        console.error(error);
      });
  }, [language]);

  useEffect(() => {
    if(cookieSettings.tracking) {
      const _paq = window._paq = window._paq || [];
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);

      (function() {
        const u = "//analytics.blaguss.eu/";
        _paq.push(['setTrackerUrl', u + 'matomo.php']);
        _paq.push(['setSiteId', '2']);
        const d = document;
        const g = d.createElement('script');
        const s = d.getElementsByTagName('script')[0];

        g.async = true;
        g.src = u + 'matomo.js';
        s.parentNode.insertBefore(g, s);
      })();
    }
  });

  const handleCookieAccept = useCallback(() => {
    sessionStorage.setItem('cookiesAccepted', 'true');
    sessionStorage.setItem('cookiesAcceptedTimestamp', new Date().getTime());

    setCookiesAccepted(true);
    setShowCookieSettings(false);
  }, []);

  const handleCookieSettings = useCallback(() => {
    setShowCookieSettings(true);
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const {
    logo,
    sectionTitle,
    sectionTitleText,
    sectionSubtitle,
    services,
    safety,
    surveyIcon,
    surveyTitle,
    surveySubTitle,
    surveyText,
    surveyButtonText,
    surveyButtonLink,
    footerText,
    safetyTitle,
    serviceTitle,
    surveyThankText,
    socialMedia,
    cookieText,
    cookieButtonText,
    cookieButtonMandatoryText,
    cookieSaveText,
    cookieSettingsText,
    cookieMandatoryText,
    cookieTrackingText,
    cookieMandatoryLabel,
    cookieTrackingLabel,
    footerLinks,
  } = data;

  const surveyLinkWithHashtag = di ? `${surveyButtonLink}&hashtag=${di}${options}` : surveyButtonLink;

  const srcMp4 = `/videos/${language}/onboard.mp4`;
  const srcWebm = `/videos/${language}/onboard.webm`;

  return (
    <div className="App">
      <Helmet>
        <title>Blaguss Onboard</title>
      </Helmet>

      <div className="flex-wrapper">
        <div>
          <Header logo={logo?.filename} />

          <main className="main-content container">

            {/*
            <VideoSection
              srcMp4={srcMp4}
              srcWebm={srcWebm}
            />
            */}

            <SectionTitle title={sectionTitle} />
            <SectionSubtitle title={sectionSubtitle} />
            <SectionTitleText title={sectionTitleText} />

            <Services
              title={serviceTitle}
              items={services}
              options={options}
              language={language}
            />

            <Safety
              title={safetyTitle}
              items={safety}
              options={options}
              language={language}
            />

            <>
              {/*
              {showThankSection ? (
                <SurveyThankSection
                  title={surveyTitle}
                  text={surveyThankText}
                />
              ) : (
                */}
              <SurveySection
                title={surveyTitle}
                subtitle={surveySubTitle}
                text={surveyText}
                buttonText={surveyButtonText}
                buttonLink={surveyLinkWithHashtag}
                textThank={surveyThankText}
                showThanks={showThankSection}
              />
            </>
          </main>
        </div>

        <footer>
          {socialMedia && <SocialMediaLinks blok={socialMedia} />}

          <Footer
            links={footerLinks}
            text={footerText}
            onClick={handleCookieSettings}
            buttonText={cookieSettingsText}
          />

          <CookieBar
            text={cookieText}
            buttonText={cookieButtonText}
            buttonTextMandatory={cookieButtonMandatoryText}
            buttonTextSettings={cookieSettingsText}
            buttonTextSave={cookieSaveText}
            labelTextMandatory={cookieMandatoryLabel}
            labelTextTracking={cookieTrackingLabel}
            descriptionTextMandatory={cookieMandatoryText}
            descriptionTextTracking={cookieTrackingText}
            onAccept={handleCookieAccept}
            showCookieSettings={showCookieSettings}
          />
        </footer>
      </div>
    </div>
  );
}

export default LandingPage;
