import React from 'react';
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { getBrowserLanguage } from './helpers';
import LanguageWrapper from './components/LanguageWrapper';
import './styles.css';


function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/:lang" element={<LanguageWrapper />} />
          <Route path="/" element={<LanguageRedirect />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

/**
 * If root is accessed directly, check for browsesr language and redirect to that language.
 * If not, redirect to the default language (en).
 *
 * @returns {void}
 */
function LanguageRedirect() {
  const defaultLanguage = "en";
  let currentLanguage = getBrowserLanguage();
  if (currentLanguage !== "en" && currentLanguage !== "de") {
    currentLanguage = defaultLanguage;
  }

  const queryParams = new URLSearchParams(window.location.search);
  let url = `/#/${currentLanguage}`
  if(queryParams.toString() !== "") {
    url += `?${queryParams.toString()}`;
  }

  window.location.href = url;

  return Navigate(url);
}

export default App;
