import React from 'react';

const SectionSubtitle = ({ title }) => (
  <h1
    className="section-subtitle"
    dangerouslySetInnerHTML={{ __html: title }}
  />
);

export default SectionSubtitle;
