import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import {
  loadCookieSettings,
  saveCookieSettings,
} from '../helpers';

const CookieBar = ({
  text,
  buttonText,
  buttonTextMandatory,
  buttonTextSettings,
  buttonTextSave,
  labelTextMandatory,
  labelTextTracking,
  descriptionTextMandatory,
  descriptionTextTracking,
  onAccept,
  showCookieSettings,
}) => {
  const [showCookieBar, setShowCookieBar] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [transitionClass, setTransitionClass] = useState("");

  const [cookieSettings, setCookieSettings] = useState(loadCookieSettings());

  useEffect(() => {
    const acceptedTimestamp = sessionStorage.getItem('cookiesAcceptedTimestamp');
    const currentTime = new Date().getTime();

    if (!acceptedTimestamp || currentTime - acceptedTimestamp > 365 * 24 * 60 * 60 * 1000) {
      setShowCookieBar(true);
    }
  }, []);

  const handleAccept = () => {
    const newCookieSettings = {
      mandatory: true,
      tracking: true,
    };

    setCookieSettings(newCookieSettings);
    saveCookieSettings(newCookieSettings);

    setTransitionClass('transition');
    setTimeout(() => {
      sessionStorage.setItem('cookiesAccepted', 'true');
      sessionStorage.setItem('cookiesAcceptedTimestamp', new Date().getTime());
      setShowCookieBar(false);

      if (onAccept) {
        onAccept();
      }
    }, 1000);
  };

  const handleMandatory = () => {
    const newCookieSettings = {
      mandatory: true,
      tracking: false,
    };

    setCookieSettings(newCookieSettings);
    saveCookieSettings(newCookieSettings);

    setTransitionClass('transition');
    setTimeout(() => {
      sessionStorage.removeItem('cookiesAccepted');
      sessionStorage.removeItem('cookiesAcceptedTimestamp');
      setShowCookieBar(false);

      onAccept();
    }, 1000);
  };

  const handleSettings = () => {
    setShowSettings(true);
  };

  const handleSave = () => {
    saveCookieSettings(cookieSettings);

    setTransitionClass('transition');
    setTimeout(() => {
      setShowCookieBar(false);

      onAccept();
    }, 1000);

  };

  const handleTrackingClick = useCallback(() => {
    setCookieSettings({
      ...cookieSettings,
      tracking: !cookieSettings.tracking,
    });
  }, [cookieSettings, setCookieSettings]);

  if(!showCookieBar && !showCookieSettings) {
    return null
  }

  return (
    <dialog open>
      <article className="cookie-bar-1">
        <div className={`belt ${transitionClass}`}>
          <div className="wrapper open">
            <img src="/images/belt_open.svg" />
          </div>

          <div className="wrapper closed">
            <img src="/images/belt_closed.svg" />
          </div>
        </div>

        {!showSettings &&
          <div>
            <p
              className="content main"
              dangerouslySetInnerHTML={{ __html: text }}
            />

            <div className="cookie-bar-buttons grid">
              <button onClick={handleMandatory}>{buttonTextMandatory}</button>
              <button onClick={handleSettings}>{buttonTextSettings}</button>
              <button onClick={handleAccept}>{buttonText}</button>
            </div>
          </div>
        }

        {showSettings &&
          <div className="settings">
            <div className="main">
              <div>
                <label className="disabled">
                  <input
                    type="checkbox"
                    name="mandatory"
                    checked
                    disabled
                  />
                  {labelTextMandatory}
                </label>

                <p>
                  {descriptionTextMandatory}
                </p>
              </div>

              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={cookieSettings.tracking}
                    name="tracking"
                    onChange={handleTrackingClick}
                  />
                  {labelTextTracking}
                </label>

                <p>
                  {descriptionTextTracking}
                </p>
              </div>
            </div>

            <div>
              <div className="cookie-bar-buttons grid">
                <button onClick={handleSave}>{buttonTextSave}</button>
              </div>
            </div>
          </div>
        }
      </article>
    </dialog>
  );
};

export default CookieBar;
