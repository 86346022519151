import React from 'react';

const SectionTitleText = ({ title }) => (
  <p
    className="section-title-text"
    dangerouslySetInnerHTML={{ __html: title}}
  />
);

export default SectionTitleText;
