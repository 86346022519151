import React from 'react';
import Accordion from './Accordion';

const Safety = ({
  title,
  items,
  options,
  language
}) => {
  return (
    <Accordion
      title={title}
      items={items}
      showIcon={false}
      isList={true}
      options={options}
      language={language}
    />
  );
};

export default Safety;
