import React from 'react';
import Accordion from './Accordion';

const Services = ({
  title,
  items,
  options,
  language,
}) => {
  return (
    <Accordion
      title={title}
      items={items}
      showIcon={true}
      isList={false}
      options={options}
      language={language}
    />
  );
};

export default Services;
