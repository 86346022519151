import React, { useState } from 'react';

const SurveySection = ({
  title,
  subtitle,
  text,
  buttonText,
  buttonLink,
  textThank,
  showThanks,
}) => {
  const [isActive, setIsActive] = useState(showThanks);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  let content = (
    <>
      <p dangerouslySetInnerHTML={{ __html: text }} />
      <a href={buttonLink} className="survey-button">
        {buttonText}
      </a>
    </>
  );

  if(showThanks) {
    content = (
      <p dangerouslySetInnerHTML={{ __html: textThank }} />
    );
  }

  return (
    <div className={`accordion ${isActive ? 'active' : ''}`}>
      <div className="accordion-item">
        <div
          className="accordion-header"
          onClick={handleToggle}
        >
          <h2>
            {title}
          </h2>
          <span className={`accordion-icon ${isActive ? 'active' : ''}`} />
        </div>

        {isActive && (
          <div className="accordion-content">
            <h3>
              {subtitle}
            </h3>

            {content}
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveySection;
