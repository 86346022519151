const areCookiesAccepted = () => {
  const acceptedTimestamp = sessionStorage.getItem('cookiesAcceptedTimestamp');
  const currentTime = new Date().getTime();

  return (
    sessionStorage.getItem('cookiesAccepted') === 'true' &&
    acceptedTimestamp &&
    currentTime - acceptedTimestamp <= 365 * 24 * 60 * 60 * 1000
  );
};

const getBrowserLanguage = () => {
  const language =  navigator.language || navigator.userLanguage || 'en';

  return language.slice(0, 2);
}

/**
 * Loads cookie settings from session storage. If no settings are found, it
 * returns default cookies.
 *
 * @returns object
 */
const loadCookieSettings = () => {
  const key = "cookieSettings";
  const defaultCookies = {
    mandatory: true,
    tracking: false,
  };

  const sessionValue = sessionStorage.getItem(key);
  if(sessionValue) {
    const parsed = JSON.parse(sessionValue);

    return {
      ...defaultCookies,
      ...parsed,
    };
  }

  return defaultCookies;
}

const saveCookieSettings = (settings) => {
  const key = "cookieSettings";
  const sessionValue = JSON.stringify(settings);
  sessionStorage.setItem(key, sessionValue);
}

export {
  areCookiesAccepted,
  getBrowserLanguage,
  loadCookieSettings,
  saveCookieSettings
};
