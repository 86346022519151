import React, { useState } from 'react';

import VideoSectionViewPort from './VideoSectionViewPort';

const Accordion = ({
  title,
  items,
  showIcon = true,
  isList = false,
  options,
  language,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  let filtered = items.filter(item => {
    const markerOptions = (item.marker === "" || item.marker == null) ? null : Number(item.marker);

    if (markerOptions === null) {
      return true;
    }

    return Number(markerOptions) === Number(options);
  });

  let content = filtered.map((item, index) => {
    let videoUrl = null;
    if(item.Service_video_url) {
      videoUrl = `/videos/${language}/${item.Service_video_url}.mp4`;
    }

    return (
      <div
        key={index}
        className={`accordion-subitem ${isActive ? 'active' : ''}`}
      >
        <div className="item-header">
          <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
          {showIcon && item.icon && (
            <img
              src={item.icon.filename}
              alt={item.title}
              className="icon"
            />
          )}
        </div>

          {/*
          {videoUrl &&
            <VideoSectionViewPort
              srcMp4={videoUrl}
            />
          */}
        <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
      </div>
    )
  });

  if(isList) {
    content = (
      <>
        {items.length > 0 && (
          <div className="item-header">
            <h3 dangerouslySetInnerHTML={{ __html: items[0].title }} />
          </div>
        )}
        <ul>
          {items.filter(item => {
              // Filterlogik wie zuvor
              const markerOptions =
                item.marker === "" || item.marker == null ? null : Number(item.marker);

                console.log(
                  "Filter Check - Title:",
                  item.title,
                  "Marker:",
                  markerOptions,
                  "Options:",
                  options
                );

              // Wenn kein Marker gesetzt ist, immer anzeigen
              if (markerOptions === null) {
                return true;
              }

              // Anzeigelogik basierend auf Optionen
              return Number(markerOptions) === Number(options);
            })
            .slice(1) // Verwendet die gefilterte Liste
            .map((item, index) => (
              <li key={index}>{item.title}</li>
            ))}
        </ul>
      </>
    );
  }

  let expandedContent = null;
  if(isActive) {
    expandedContent = (
      <div className="accordion-content">
        {content}
      </div>
    );
  }

  return (
    <div className={`accordion ${isActive ? 'active' : ''}`}>
      <div className={`accordion-item ${isActive ? 'active' : ''}`}>
        <div className="accordion-header" onClick={handleToggle}>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
        </div>

        {expandedContent}
      </div>
    </div>
  );
};

export default Accordion;
